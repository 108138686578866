import React from "react";
import SvgDarkLogo from "../assets/images/vector/components/common/logo_amzscout_black.svg";
import TrackedLink from "../components/Analytics/TrackedLink";
import "../assets/scss/fonts/fonts.scss";
import "../assets/scss/index.scss";
import "../components/why-not-available/index.scss";

const PgWNA = () => (
  <div className="PgWNA">
    <div className="PgWNA-block">
      <img className="PgWNA-block__logo" src={SvgDarkLogo} alt="" />
      <p className="PgWNA-blockMessage">
        This website is not available in your region according to current{" "}
        <TrackedLink
          path="/terms-of-use/#why-not-available"
          rel="nofollow"
          category="WhyNotAvailable"
          action="termsAndPolicies"
          class="PgWNA__blockLink"
          target
          isLink={true}
        >
          terms and policies
        </TrackedLink>{" "}
        If you think it's a mistake, please contact our customer service{" "}
        <TrackedLink
          path="mailto:support@amzscout.net"
          rel="nofollow"
          category="WhyNotAvailable"
          class="PgWNA__blockLink"
          action="support@amzscout.net"
        >
          support@amzscout.net
        </TrackedLink>
      </p>
    </div>
  </div>
);

export default PgWNA;
